.card-shadow {
  border: 0px solid var(--text-color-10);
  box-shadow: var(--card-shadow);
}

.card {
  border: 0;
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius);
}

.card-header {
  background-color: var(--light-color);
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.card-footer {
  background-color: var(--light-color);
}

.card-dashboard-content {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.card-shadow .card-header {
  /* padding: 1rem; */
  background-color: var(--primary-color-5);
  border-bottom: 1px solid var(--text-color-10);
}
