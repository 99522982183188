.modal-header {
  border-bottom: 1px solid var(--text-color-10);
}

.modal-footer {
  padding-right: 1rem !important;
}

.modal_title {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-md);
}

.confirmation-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

/* .modal-content {
  max-height: 100vh;
  overflow-y: auto;
} */

.confirmation-modal-header span {
  color: var(--text-color-10);
  font-size: 40px;
}

.confirmation-modal-header.failed span {
  color: var(--error-color);
}

.confirmation-modal-header.success span {
  color: var(--primary-color);
}
