.donor-badge-container {
  /* background-color: var(--text-color-5); */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin-bottom: 2rem; */
  /* padding: 2rem 0; */
  flex-direction: column;
  background-position: center;
}

.donor-badge-card-container {
  min-height: 30vh;
  border-radius: 16px;
  box-shadow: 0 19px 38px rgba(196, 196, 196, 0.3),
    0 15px 12px rgba(224, 224, 224, 0.22);
  display: flex;
  flex-direction: column;
}

.donor-badge-card-container .donor-badge-card-details-container {
  flex: 1;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    url("../../../assets//blood-transport.jpg");
  background-position: center;
  padding: 1.5rem 2rem;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.donor-badge-card-container
  .donor-badge-card-details-container
  .title-container {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.donor-badge-card-container
  .donor-badge-card-details-container
  .title-container
  h2 {
  color: var(--light-color);
  /* font-weight: 900; */
}

.donor-badge-card-details-item {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.donor-badge-card-details-item p {
  font-size: 14px;
  color: var(--light-color);
  opacity: 0.8;
}

.donor-badge-card-details-item-blood-group {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-color);
  border-radius: 50%;
  margin-top: 1.5rem;
}

.donor-badge-card-details-item-blood-group p {
  color: var(--primary-color);
  font-size: 15px;
}

.donor-badge-card-footer {
  background-color: var(--light-color);
  min-height: 40px;
  display: flex;
  padding: 1rem 2rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.donor-badge-card-footer span {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8;
}

.badge-card-actions {
  margin-top: 1.5rem;
}

.badge-card-action-button {
  cursor: pointer;
}

.badge-card-action-button:hover {
  opacity: 1;
  text-decoration: underline;
}

.printme {
  display: none;
}
@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
}

.donor-badge-card-footer .card-logo {
  width: 60px;
  height: 35px;
}

.donor-badge-card-footer .card-logo img {
  aspect-ratio: 3/2;
  width: 100%;
  object-fit: contain;
}

.donor-badge-card-container .donor-badge-card-details-container.white {
  background: #fff;
}

.donor-badge-card-container .donor-badge-card-details-container.pink {
  background: pink;
}

.donor-badge-card-container .donor-badge-card-details-container.yellow {
  background: yellow;
}

.donor-badge-card-container .donor-badge-card-details-container.green {
  background: green;
}

.donor-badge-card-container
  .donor-badge-card-details-container.white
  .title-container
  h2,
.donor-badge-card-container
  .donor-badge-card-details-container.pink
  .title-container
  h2,
.donor-badge-card-container
  .donor-badge-card-details-container.yellow
  .title-container
  h2,
.donor-badge-card-container
  .donor-badge-card-details-container.green
  .title-container
  h2,
.donor-badge-card-container {
  color: var(--text-color);
}

.donor-badge-card-details-container.white p,
.donor-badge-card-details-container.yellow p,
.donor-badge-card-details-container.pink p,
.donor-badge-card-details-container.green p {
  color: var(--text-color);
}

.donor-badge-container.white .donor-badge-card-footer {
  background-color: var(--text-color-5);
}

.donor-badge-container.pink .donor-badge-card-footer {
  background-color: rgb(249, 184, 195);
}

.donor-badge-container.yellow .donor-badge-card-footer {
  background-color: rgb(245, 245, 70);
}

.donor-badge-container.green .donor-badge-card-footer {
  background-color: rgb(11, 141, 11);
}

.donor-badge-card-details-container.white
  .donor-badge-card-details-item-blood-group {
  background-color: var(--primary-color-10);
}
