.notification-counter {
  top: 3px;
  right: 5px;
  background-color: var(--error-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-color);
}

.notification-counter span {
  color: var(--light-color);
  font-size: 9px;
  font-weight: var(--font-weight-md);
}

.notification-counter:hover span {
  color: var(--light-color) !important;
}
