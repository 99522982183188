.date-checker-icon-container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-5);
  border-radius: 50%;
  margin-bottom: 1.5rem;
}

.date-checker-icon-container span {
  color: var(--primary-color);
  font-size: 28px;
}
