.splash-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  flex-direction: column;
}

.splash-container .fetch-service-error-container {
  max-width: 300px;
  margin-top: 2rem;
}

.splash-container .fetch-service-error-container p {
  margin-bottom: 1rem;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 980px) {
}
