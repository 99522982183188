.print-header-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  gap: 1rem;
}

.print-header-title {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
