@media print {
  body,
  html {
    height: auto;
    overflow: visible !important;
  }

  .printable-content {
    height: auto;
    overflow: visible !important;
  }
  .footer-worksheet {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background: #fff;
    font-size: 12px;
  }

  .printable-content::after {
    content: "";
    display: block;
    height: 50px; /* Adjust according to your footer height */
  }
}
