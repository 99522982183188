.card-header-item {
  display: flex;
  gap: 1rem;
}

.donor-profile-container {
  min-height: 30vh;
  gap: 2rem;
}

.donor-profile-image-container {
  width: 150px;
  height: 150px;
  border-radius: 16px;
  position: relative;
}

.donor-profile-image-container img {
  aspect-ratio: 2/2;
  object-fit: contain;
  width: 100%;
  /* width: 150px;
  height: 150px;
  border-radius: 16px; */
}

.donor-profile-container .donor-profile-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.donor-profile-container .donor-profile-info-container div.info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
}

.donor-profile-container .donation-profile-blood-group-container {
  /* background-color: var(--primary-color);
  min-width: 40px;
  height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}

.donor-profile-container .donation-profile-blood-group-container span {
  color: var(--text-color);
}

.donor-profile-container .donor-profile-info-name-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blocked-info {
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  /* min-height: 80px; */
  display: flex;
  /* flex-direction: column; */
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  gap: 0.5rem;
  padding: 0.3rem;
}

.blocked-info .title {
  font-size: 12px;
}

.blocked-info .value {
  background-color: var(--primary-color);
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.blocked-info .value.no {
  background-color: var(--success-color);
}

.blocked-info .value span {
  color: white;
  font-weight: 900;
}
