.filters-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
}

.filters-container .header {
  height: 40px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-color-10);
}

.filters-container .header .title {
  flex: 1;
}

.filters-container .filter-footer {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid var(--text-color-10);
  padding: 0.5rem;
  align-self: flex-end;
}

.filters-container .checkbox-container {
  max-width: 250px;
}

.filters-date-list .filters-date-list-item {
  min-height: 40px;
  display: flex;
  color: var(--primary-color);
  align-items: center;
}

.filters-date-list .filters-date-list-item:hover {
  opacity: 0.8;
  cursor: pointer;
}

.filters-date-list .filters-date-list-item.active {
  color: var(--primary-color);
}

.filters-date-list .filters-date-list-item .icon-container {
  display: none;
}

.filters-date-list .filters-date-list-item.active .icon-container {
  display: flex;
}
