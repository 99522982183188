.login-tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.login-tabs-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: center;
  border-radius: 32px;
  gap: 0.5rem;
  cursor: pointer;
  flex: 1;
}

.login-tabs-item {
  font-weight: 600;
  color: var(--text-color);
}

.login-tabs-item.active {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color-10);
}

.login-tabs-item.active,
.login-tabs-item.active span {
  color: var(--primary-color);
}
