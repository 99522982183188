.partners-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.partners-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 30%;
}
