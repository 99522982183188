.breadcrumb-nav-container {
  display: flex;
  align-items: center;
  min-height: 60px;
  gap: 1.5rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  opacity: 0.8;
}

.breadcrumb-item a {
  color: var(--text-color-60);
  font-weight: 700;
}

.breadcrumb-item .breadcrumb-item-name {
  font-weight: 700;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

ol {
  margin: 0;
  padding: 0;
}

.breadcrumb-item.active a {
  color: var(--primary-color);
}
