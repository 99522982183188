.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--primary-color) var(--primary-color) transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-container.fullscreen {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader.primary {
  border-color: var(--primary-color) var(--primary-color) transparent;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent var(--secondary-color) var(--secondary-color);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader.primary::after {
  border-color: transparent var(--secondary-color) var(--secondary-color);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* scretton loader  */
.title-loader-container {
  width: 250px;
  background-color: var(--text-color-10);
  height: 10px;
  border-radius: 16px;
}

.par-one-loader-container {
  width: 150px;
  background-color: var(--text-color-10);
  height: 10px;
  border-radius: 16px;
}

.par-two-loader-container {
  width: 80px;
  background-color: var(--text-color-10);
  height: 10px;
  border-radius: 16px;
}
