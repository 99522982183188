.footer-container {
  background-color: var(--light-color);
}

.footer-container .container {
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
}

.footer-container .footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.footer-container .container span {
  font-size: var(--font-size-sm);
}

.footer-container .middle-container {
  flex: 1;
}

.footer-container .footer-left-container {
  display: flex;
  align-items: center;
}

.footer-container .footer-left-container .social-icon-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
}

.footer-container .footer-left-container .social-icon-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}

@media screen and (max-width: 980px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}
