.badge-default,
.badge-incoming,
.badge-pending {
  background-color: var(--text-color-10) !important;
  color: var(--text-color);
}

.badge-primary {
  background-color: var(--primary-color-10) !important;
  color: var(--primary-color);
}

.badge-cancelled,
.badge-failed,
.badge-rejected,
.badge-blocked,
.badge-expired {
  background-color: var(--error-color-10) !important;
  color: var(--error-color);
}

.badge-processing,
.badge-used,
.badge-USED {
  background-color: var(--info-color-10) !important;
  color: var(--info-color);
}

.badge-success,
.badge-approved,
.badge-received,
.badge-transferred {
  background-color: var(--success-color-10) !important;
  color: var(--success-color);
}

.badge-dispatched,
.badge-info {
  background-color: var(--info-color-10) !important;
  color: var(--info-color);
}
