.contact-box-with-number-container {
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0;
  border-radius: var(--border-radius);
  border-bottom: 4px solid var(--primary-color);
}

.contact-box-with-number-container .contact-box-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.contact-box-with-number-container .contact-box-icon img {
  width: 100%;
}

.contact-box-with-number-container .contact-box-numbers h3 {
  margin-bottom: 1.5rem;
}

.contact-box-with-number-container .contact-box-number {
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
