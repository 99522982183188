.who-can-give-item {
  display: flex;
  padding: 1.5rem 0;
}

.who-can-give-item .who-can-give-item-icon {
  height: 24px;
  width: 24px;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
}

.who-can-give-item .who-can-give-item-icon span {
  color: var(--primary-color);
  font-size: var(--font-size-sm);
}

.who-can-give-item .who-can-give-item-description {
  flex: 1;
}
