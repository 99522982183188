.list-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 1rem;
}

.list-item-card {
  border: 1px solid var(--border-color);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.list-item:hover {
  opacity: 0.8;
}

.list-middle-content {
  flex: 1;
  margin-right: 1rem;
}

.list-item .list-img-container {
  width: 35px;
  height: 35px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item img {
  width: 25px;
  height: 25px;
  border-radius: var(--border-radius-half);
}

.list-item .list-item-name {
  text-transform: capitalize;
}

.list-item .icon-container span {
  color: var(--primary-color);
}
