.main-landing-container {
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.page-landing-wrapper {
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-top: 2rem;
  z-index: 1;
}

.landing-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}

.landing-section.half-height {
  min-height: 70vh;
}

.landing-section.light {
  background-color: var(--light-color);
}

.landing-section.dark-primary {
  background-color: var(--dark-primary-color-150);
}

.landing-section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
