.auth-form-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 1rem;
}

.landing-section-img img {
  aspect-ratio: 3/3;
  object-fit: contain;
  width: 100%;
}

/* home-dashboard-layout */
.home-dashboard-layout-container {
  display: flex;
  height: 100vh;
}

.dashboard-layout {
  padding-bottom: 80px;
  flex: 1;
  overflow-x: hidden;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .auth-form-layout {
    align-items: start;
  }
}

@media screen and (max-width: 768px) {
  .auth-form-layout {
    align-items: start;
  }

  .landing-section-img img {
    aspect-ratio: 4/2;
    object-fit: contain;
    width: 100%;
    margin: 2rem 0;
  }
}

.spacer {
  height: 2rem;
}

@media screen and (max-width: 980px) {
  .auth-form-layout {
    align-items: start;
  }
}
