.landing-navbar-container {
  background-color: transparent;
  position: relative;
  transition: position 0.4s;
}

.landing-navbar-container.nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.landing-navbar-container.nav-light {
  background-color: var(--light-color);
  box-shadow: var(--navbar-box-shadow);
}

.landing-navbar-container.nav-transparent {
  background-color: transparent;
}

.landing-navbar-container.navbar-shadow {
  box-shadow: var(--navbar-box-shadow);
}

.navbar-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: var(--light-color) !important;
  box-shadow: var(--navbar-box-shadow);
}

.navbar-sticky-top .logo-text {
  color: var(--primary-color) !important;
}

.navbar-sticky-top .mobile-container span {
  color: var(--text-color) !important;
}

.navbar-sticky-top.text-color-dark .btn-transparent.light {
  color: var(--text-color) !important;
}

.landing-navbar-container .menu-landing-container {
  min-height: var(--navbar-height);
  display: flex;
  align-items: center;
  height: 100%;
}

.landing-navbar-container .left-container {
  flex: 1;
}

.landing-navbar-container .left-container,
.landing-navbar-container .right-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.landing-navbar-container .mobile-navbar {
  display: none;
  min-height: var(--navbar-height);
  background-color: var(--light-color);
  box-shadow: var(--card-shadow);
}

.landing-navbar-container .mobile-navbar .nav-item {
  min-height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  opacity: 1;
  transition: opacity 0.4s;
  border-bottom: 2px solid var(--primary-color-5);
}

.landing-navbar-container .mobile-navbar .nav-item a {
  font-weight: var(--font-weight);
  font-size: var(--font-size-md);
}

.landing-navbar-container .mobile-navbar .nav-item:hover {
  opacity: 0.5;
  border-bottom: 2px solid var(--primary-color);
}

.landing-navbar-container .mobile-navbar .nav-item a {
  color: var(--text-color);
}

.navbar-notification-container {
  position: relative;
}

@media (max-width: 768px) {
  .landing-navbar-container .mobile-navbar.show {
    display: flex;
    flex-direction: column;
  }

  .landing-navbar-container .mobile-navbar.hide {
    display: none;
  }
}
