/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .hidden-xs {
    display: none !important;
  }
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }
  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 980px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }

  .mb-sm-1 {
    margin-bottom: 1rem;
  }

  .w-sm-100 {
    width: 100%;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}
