.dashboard-header-container {
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 1rem; */
}

.dashboard-header-container .left-container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.dashboard-header-container .left-container .info-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow-x: auto;
  flex-wrap: wrap;
}

.dashboard-header-container .left-container .info-container .info-item {
  display: flex;
  align-items: center;
  /* margin-top: 0.6rem; */
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding: 0.4rem;
}

.dashboard-header-container .left-container .info-container .info-item span {
  flex: 1;
}

.dashboard-header-container .left-container .info-container .info-item.bg {
  border-right: 1px solid var(--text-color-10);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--text-color-10);
  border-radius: 50px;
}

.header-filter-container {
  background-color: var(--light-color);
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  box-shadow: var(--card-shadow);
}

.header-filter-container .header-filter-search-container {
  flex: 1;
}

.header-filter-container .filters-menus {
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.header-filter-container .filters-menus::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.card-header-container {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  background-color: var(--text-color-10);
}

.card-header-container h3 {
  font-size: var(--font-size);
}

.card-header-container .card-header-action-btn {
  display: flex;
  align-items: center;
}

.card-header-container.border-bottom {
  border-bottom: 1px solid var(--text-color-10);
}
