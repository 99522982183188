.input-container {
  min-height: 40px;
  margin: 0.5rem 0;
}

.input-container .input-content {
  display: flex;
  align-items: center;
  background-color: var(--light-color);
  border-radius: var(--border-radius);
  padding: 0 1rem;
  border: 2px solid var(--text-color-10);
  transition: border 0.4s;
}

.input-container .input-content.no-border {
  border: 0px;
}

.input-container .input-content.shadow {
  box-shadow: var(--input-shadow);
}

.input-container .input-content .input-input {
  flex: 1;
}

.input-container .input-content .input-input input::placeholder,
.input-container .input-content .input-input textarea::placeholder {
  font-size: var(--font-size-sm);
}

.input-container .input-content .input-input input,
.input-container .input-content .input-input select,
.input-container .input-content .input-input textarea {
  border: 0;
  outline: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.input-container.sm .input-content .input-input input,
.input-container.sm .input-content .input-input select,
.input-container.sm .input-content .input-input textarea {
  font-size: 12px;
}

.input-container .input-content .input-input textarea {
  resize: none;
  max-height: 200;
}

.input-container .input-content .left-container {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.8rem;
}

.input-container.sm .input-content .left-container {
  min-height: 35px;
}

.input-container .helper-section {
  margin: 0.5rem 0;
}

.input-container .input-content:focus-within {
  border: 2px solid var(--primary-color);
}

.input-container.no-border .input-content {
  border-width: 0;
}

.input-container.no-border .input-content:focus-within {
  border-width: 0px;
}

.input-container.disabled-input .input-content,
.input-container.disabled-input .input-content input {
  background-color: var(--primary-color-5);
}

.input-container.errored-input .input-content {
  border: 2px solid var(--error-color);
}

.input-container.errored-input .material-symbols-outlined {
  color: var(--error-color);
}

.input-container.success-input .input-content {
  border: 2px solid var(--success-color);
}

.input-container.success-input .material-symbols-outlined {
  color: var(--success-color);
}

.css-13cymwt-control {
  background-color: var(--light-color) !important;
  border-color: var(--light-color);
  border-radius: 4px;
  border-style: none;
  border-width: 0px !important;
  box-sizing: border-box;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus {
  border-color: transparent !important;
  border-width: 0 !important;
}

.input-container .input-profile-data {
  margin: 1rem 0;
  color: var(--primary-color);
}

.helper-link {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.helper-link a {
  font-weight: var(--font-weight-md);
}

/* switch */
.switch-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.switch-container .switch-content {
  display: flex;
  align-items: center;
  height: 16px;
  min-width: 45px;
  background-color: var(--text-color-10);
  position: relative;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: var(--card-shadow);
}

.switch-container .switch-content .check {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--text-color-60);
  position: absolute;
  left: -5px;
  margin: 0.3rem;
  transition: position 0.4s;
  box-shadow: var(--dropdown-shadow);
}

.switch-container .switch-content.checked {
  background-color: var(--primary-color);
  position: relative;
}

.switch-container .switch-content.checked .check {
  position: absolute;
  left: 20px;
  background-color: var(--secondary-color);
}

.switch-container .description {
  margin-left: 1rem;
}

/* checkbox */
.checkbox-container {
  padding: 0.5rem 1rem;
  min-height: 40px;
  cursor: pointer;
}

.checkbox-container label {
  display: flex;
  gap: 1rem;
}

.right-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.css-16xfy0z-control,
.css-16xfy0z-control:focus,
.css-16xfy0z-control:active,
.css-16xfy0z-control input,
.react-select-65-input {
  background-color: transparent;
  border: 0 !important;
}

.dropdown-header-title {
  display: flex;
  align-items: center;
  min-height: 55px;
}

.dropdown-header-title .title {
  font-size: var(--font-size);
  flex: 1;
}

.css-t3ipsp-control {
  border: 0 !important;
  box-shadow: none !important;
}

.checkbox-group-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.css-1nmdiq5-menu {
  background-color: red !important;
  position: absolute;
  z-index: 1000;
}
