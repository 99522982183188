.card-item-feature {
  height: 450px;
  border-bottom: 5px solid var(--primary-color);
  background-color: var(--light-color);
  margin-bottom: 1.5rem;
}

.card-item-feature.primary {
  background-color: var(--primary-color) !important;
}

.card-item-feature .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-item-feature .card-item-feature-icon-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.card-item-feature .card-item-feature-icon-container img {
  width: 80px;
}

.card-item-feature .card-item-feature-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex: 1;
}

.card-item-feature .card-item-feature-description p {
  opacity: 0.8;
}

.card-item-feature .card-item-feature-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.card-item-feature.primary .text-primary,
.card-item-feature.primary p {
  color: var(--light-color) !important;
}
