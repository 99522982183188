.explain-stock-container {
  display: flex;
  align-items: center;
  min-height: 50px;
  gap: 1rem;
}

.explain-stock-item {
  display: flex;
  align-items: center;
}

.explain-stock-item .color {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  margin-right: 0.5rem;
}

.explain-stock-item .red {
  background-color: red;
}

.explain-stock-item .green {
  background-color: green;
}

.explain-stock-item .yellow {
  background-color: #f0b807;
}

.explain-stock-item .other {
  background-color: purple;
}
