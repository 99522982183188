.empty-activity {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 270px;
  color: var(--text-color-60);
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--light-color);
}

.empty-activity .icon-container {
  background-color: var(--primary-color-5);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.empty-activity .icon-container span {
  font-size: 24px;
  color: var(--primary-color);
}

.empty-activity-description-container {
  max-width: 30vw;
}
