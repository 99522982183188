.intro-wrapper {
  min-height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(27, 0, 1, 1),
      rgba(39, 4, 6, 0.75)
    ),
    url("../../../assets//home-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 5rem 0;
  display: flex;
  align-items: center;
}

.intro-wrapper .intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.intro-wrapper .intro-container .intro-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.intro-wrapper .intro-container .intro-description h1 {
  color: var(--light-color);
  font-weight: var(--font-weight-md);
  /* font-size: 100px; */
  font-size: 5rem;
  font-size: clamp(2rem, -0.9230769230769231rem + 8.205128205128204vw, 5rem);
  font-family: "Montagu Slab", serif;
  position: relative;
}

.intro-wrapper .intro-container .intro-description h1 span .border {
  /* height: 5px;
  background-color: var(--primary-color); */
  /* width: 80px; */
  /* position: absolute;
  border: 0; */
}

.intro-wrapper .intro-container .intro-description h1 span .border.bottom {
  bottom: 0;
}

.intro-wrapper .intro-container .intro-description p {
  color: var(--light-color);
  opacity: 0.5;
  font-size: var(--font-size-md);
  padding-bottom: 1.5rem;
}

.intro-wrapper .intro-container .intro-description .intro-paragraph-container {
  margin-bottom: 1rem;
}

.intro-wrapper .intro-container .intro-buttons-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.intro-wrapper .intro-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 768px) {
  .intro-wrapper .intro-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .intro-wrapper .intro-container .intro-description {
    text-align: center;
  }

  .intro-wrapper .intro-container .intro-buttons-container {
    justify-content: center;
  }

  .intro-wrapper .intro-container .intro-description h1 {
    font-size: 80px;
    margin: 0 2rem;
  }

  .intro-wrapper .intro-container .intro-description p {
    /* margin-bottom: 2rem; */
  }
}

@media screen and (max-width: 980px) {
  .intro-wrapper .intro-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .intro-wrapper .intro-container .intro-description {
    text-align: center;
    /* gap: 3rem; */
  }

  .intro-wrapper .intro-container .intro-buttons-container {
    justify-content: center;
  }

  .intro-wrapper .intro-container .intro-description h1 {
    font-size: 60px;
  }

  .intro-wrapper .intro-container .intro-description p {
    /* margin-bottom: 2rem; */
  }
}
