.nav-tabs {
  /* z-index: 100 !important; */
  margin-bottom: 1rem !important;
}
.tab {
  border-bottom: 1px solid var(--text-color-10);
  margin-top: 0;
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab::-webkit-scrollbar {
  display: none;
}

.tab .nav-item button,
.tab .nav-item {
  font-weight: 700;
  color: var(--text-color);
}

.nav-tabs .nav-link {
  opacity: 0.5;
  border: 0;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-radius: 0;
}

.nav-tabs .nav-link.active {
  opacity: 1;
  border-bottom: 3px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}

.tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}
.tab-content > .tab-pane.active {
  height: auto;
}

.floating-buttons {
  position: relative;
  top: 6.4rem;
  right: 0;
  z-index: 100;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}
