.text-bold {
  font-weight: var(--font-weight-md);
}

.text-capitalized {
  text-transform: capitalize;
}

.text-default {
  color: var(--text-color);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-hover-decoration:hover {
  text-decoration: underline;
}

p {
  font-size: var(--font-size);
  margin-bottom: 0;
  color: var(--text-color-60);
}

h1 {
  font-size: var(--font-size-lg);
  margin-bottom: 0;
  font-weight: var(--font-weight-md);
}

h2 {
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-md);
}

h3 {
  font-size: var(--font-size-md);
  margin-bottom: 0;
  font-weight: var(--font-weight-md);
}

.success-text {
  color: var(--success-color);
}

a,
a:hover {
  text-decoration: none;
  color: var(--primary-color);
  font-size: var(--font-size);
  opacity: 1;
  transition: opacity 0.4s;
}

/* a:hover {
  color: var(--primary-color);
} */

.error-text {
  color: var(--error-color);
}

.text-error {
  color: var(--error-color);
}

.text-disabled {
  opacity: 0.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

label {
  font-weight: 600;
}

a.hover-decoration:hover {
  text-decoration: underline;
}
