.landing-title-container {
  display: flex;
  margin-bottom: 4rem;
  flex-direction: column;
}

.landing-title-container.center {
  align-items: center;
}

.landing-title-container h1 {
  font-weight: var(--font-weight-md);
  font-size: 40px;
  color: var(--text-color);
  font-family: "Montagu Slab", serif;
}

.landing-title-container p {
  margin-top: 1.5rem;
}

.landing-title-container.light h1,
.landing-title-container.light p {
  color: var(--light-color);
}
