
:root {
  /* body color  */
  --bg-color: #fbfbfb;

  /* light colors */
  --light-color: #ffffff;

  /* primary colors */
  --primary-color: #ac0207;
  --primary-color-60: #a9373b;
  --primary-color-10: #d4cfd0;
  --primary-color-5: #f9f9f9;

  /* dark primary */
  --dark-primary-color-10: rgb(127, 2, 6);
  --dark-primary-color: #8b0307;
  --dark-primary-color-150: #410104;
  --dark-primary-color-200: #110101;

  /* secondary color */
  --secondary-color: #ff6d00;
  --secondary-color-60: #f48e41;
  --secondary-color-10: #f3e0d1;

  /* dark secondary */
  --dark-secondary-color: #ab4b01;
  --dark-secondary-color-60: #d15c02;
  --dark-secondary-color-10: #e66604;

  /* success color */
  --success-color: #008000;
  --success-color-60: #7eb97e;
  --success-color-10: #c5dcc5;

  /* warnings colors */
  --warning-color: #ff6d00;
  --warning-color-60: #f48e41;
  --warning-color-10: #f3e0d1;

  /* errors colors */
  --error-color: #d10000;
  --error-color-60: #d97575;
  --error-color-10: #fac3c3;

  /* info colors */
  --info-color: #0026d1;
  --info-color-60: #4d65d2;
  --info-color-10: #cfd3df;

  /* text colors */
  --text-color: #030202;
  --text-color-60: #201111;
  --text-color-10: #e9e9e9;
  --text-color-5: #f9f9f9;

  /* border colors */
  --border-color: #ddd;

  /* radius */
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-md: 16px;
  --border-radius-lg: 32px;
  --border-radius-half: 50%;

  /* margins */
  --margin: 16px;
  --margin-sm: 8px;
  --margin-lg: 32px;

  /* paddings */
  --padding: 16px;
  --padding-sm: 8px;
  --padding-lg: 32px;

  /* fonts size */
  --font-size: 14px;
  --font-size-xsm: 12px;
  --font-size-sm: 13px;
  --font-size-md: 16px;
  --font-size-md-lg: 18px;
  --font-size-md-xlg: 20px;
  --font-size-lg: 30px;
  --font-size-xlg: 60px;

  /* fonts weight */
  --font-weight-sm: 300;
  --font-weight: 600;
  --font-weight-md: 700;
  --font-weight-lg: 900;

  /* heights */
  --navbar-height: 55px;

  /* shadows */
  --navbar-box-shadow: rgba(159, 159, 159, 0.1) 1px 4px 6px -1px,
    rgba(172, 172, 172, 0.06) 1px 2px 2px -1px;

  --form-box-shadow: 0 19px 38px rgba(232, 235, 239, 0.5),
    0 15px 12px rgba(232, 235, 239, 0.5);

  --sidebar-box-shadow: 0 19px 38px rgba(214, 214, 214, 0.3),
    0 15px 12px rgba(199, 199, 199, 0.22);

  --dropdown-shadow: 0 19px 38px rgba(214, 214, 214, 0.3),
    0 15px 12px rgba(199, 199, 199, 0.22);

  --card-shadow: rgba(159, 159, 159, 0.1) 1px 4px 6px -1px,
    rgba(172, 172, 172, 0.06) 1px 2px 2px -1px;
}



.App {
  background-color: var(--bg-color);
  height: 100vh;
}

h1 {
  font-size: 4rem;
  font-size: clamp(3rem, -0.9230769230769231rem + 8.205128205128204vw, 4rem);
}

h1,
h2,
h3 {
  font-family: "font-bold", sans-serif;
  margin-bottom: 0;
  line-height: normal !important;
}

h3 {
  font-size: 1rem;
  font-size: clamp(1rem, -0.9230769230769231rem + 8.205128205128204vw, 2rem);
}

hr {
  color: var(--border-color);
}
@media screen {
  .noPrint {
  }
  .noScreen {
    display: none;
  }
}

@media print {
  .noPrint {
    display: none;
  }
  .noScreen {
  }
}

@page {
  margin: 1rem;
}

/* Apply landscape only to specific div */
.landscape-content {
  @page {
    size: landscape; /* Apply landscape orientation */
  }
  width: 100%;
}

/* Portrait content remains unchanged */
.portrait-content {
  @page {
    size: portrait; /* Default to portrait orientation for others */
  }
}

.col-5th {
  width: 20%; /* 100% / 5 = 20% */
  float: left;
}

/* Tablet (768px and up) */
@media (max-width: 768px) {
  .col-5th {
    width: 33.33%; /* 3 columns */
  }
}

/* Mobile (576px and up) */
@media (max-width: 576px) {
  .col-5th {
    width: 50%; /* 2 columns */
  }
}

/* Extra small (below 576px) */
@media (max-width: 400px) {
  .col-5th {
    width: 100%; /* 1 column */
  }
}
