@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@font-face {
  src: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
}


body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 1.25rem;
}



body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  overflow-y: hidden;
  overflow-x: hidden;
  color: var(--text-color);
  font-size: 0.9rem;
  font-size: clamp(0.9rem, 0.5153846153846153rem + 1.641025641025641vw, 0.9rem);
}
