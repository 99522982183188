.header-worksheet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.work-loc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-worksheet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.no-bg {
  background-color: white !important;
}

@media print {
  .page {
    page-break-after: always; /* Ensures that each page breaks after 13 items */
  }

  .print-item {
    break-inside: avoid; /* Prevent items from breaking across pages */
    margin-bottom: 10px; /* Optional, for some spacing */
  }

  .page table td,
  .page table th {
    font-size: 10px !important;
  }
}
