.reactive-to-test {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none; /* Prevent interaction */
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Create 5 columns */
  grid-template-rows: repeat(2, 1fr); /* Create 2 rows */
  justify-items: center;
  align-items: center;
  opacity: 0.9; /* Faint watermark */
  user-select: none; /* Prevent selecting the watermark */
  overflow: hidden;
}

.reactive-to-test span {
  font-size: 50px;
  color: red;
  transform: rotate(-30deg); /* Add rotation to the text */
  white-space: nowrap;
}

.verifying-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
}
