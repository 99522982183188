.btn {
  outline: none;
  color: var(--text-color);
  padding: 0.3rem 1rem;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  opacity: 1;
  transition: opacity 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  /* min-height: 40px; */
  transition: background-color 0.4s;
  box-shadow: none;
}

.btn:active,
.btn:focus,
.btn.focus {
  box-shadow: none;
  border: none;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: background-color 0.5ms;
  color: var(--light-color);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--dark-primary-color-10);
  border-color: var(--primary-color);
  box-shadow: none;
}

.btn-primary .icon {
  color: var(--light-color);
}

.btn-transparent {
  border: 0;
  background-color: transparent;
  color: var(--text-color) !important;
}

.btn-transparent .icon {
  color: var(--text-color);
}

.btn-transparent.primary {
  color: var(--primary-color) !important;
}

.btn-transparent.light {
  color: var(--light-color);
}

.btn-transparent.bordered {
  color: var(--text-color);
  border: 2px solid var(--text-color-10);
}

.btn-transparent.bordered-md {
  color: var(--text-color);
  border: 2px solid var(--text-color-10);
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus {
  background-color: transparent !important;
  opacity: 0.9;
}

.btn-transparent.light {
  color: var(--light-color) !important;
}

.btn-transparent.dark {
  color: var(--text-color) !important;
}

/* .btn-transparent.light:hover,
.btn-transparent.light:active,
.btn-transparent.light:focus {
  color: var(--light-color);
} */

/* button with image */
.btn .left-img-container {
  width: 25px;
  height: 25px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn .left-img {
  aspect-ratio: 3/3;
  object-fit: contain;
  width: 80%;
  border-radius: var(--border-radius-half);
}

/* btn small size */
.btn-sm {
  min-height: 35px;
}

.btn-xs {
  height: 20px;
  /* padding: 10px; */
}

.btn-xsm {
  height: 20px;
  padding: 5px 2px;
}

.btn-radius-50 {
  border-radius: 25px;
}

/* btn dropdown menus */
.menu-container-no-shadow .dropdown-menu {
  box-shadow: none !important;
}

/* btn disabled */
.btn[disabled] {
  background-color: var(--text-color-10);
  color: var(--text-color-60);
  border: 0;
}

/* btn default */
.btn-default {
  background-color: var(--text-color-10);
  color: var(--text-color-60);
  border: 0;
}

.btn-default .icon {
  color: var(--text-color);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  border: 1px solid var(--text-color-10);
  background-color: var(--text-color-10);
  color: var(--text-color);
}
/* .btn-transparent.bordered:hover,
.btn-transparent.bordered-md:hover,
.btn-transparent.bordered:focus,
.btn-transparent.bordered-md:focus {
  color: var(--text-color);
  border: 2px solid var(--text-color-10);
} */

/* .btn-transparent.primary:hover,
.btn-transparent.primary:active,
.btn-transparent.primary:focus,
.btn-transparent.bordered:hover,
.btn-transparent.bordered:active,
.btn-transparent.bordered:focus {
  color: var(--text-color);
} */

/* .btn-primary:hover,
.btn-primary:focus {
  background-color: var(--dark-primary-color);
} */

/* .btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--dark-primary-color);
  color: var(--light-color);
} */

.btn-secondary {
  background-color: var(--secondary-color);
  outline: none;
  border: 0;
  color: var(--light-color);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: var(--dark-secondary-color-10);
  color: var(--light-color);
}

.btn-secondary .icon {
  color: var(--light-color);
}

.btn-bordered {
  border: 2px solid var(--text-color-10);
  color: var(--text-color);
}

/* .btn-bordered.light {
  border: 2px solid var(--light-color);
  color: var(--light-color);
} */

/* .btn-bordered.primary {
  border: 2px solid var(--primary-color); */
/* color: var(--primary-color); */
/* } */

/* .btn-bordered.transparent { */
/* border: 2px solid var(--primary-color);
  color: var(--primary-color); */
/* background-color: transparent !important;
} */

/* .btn-bordered:hover,
.btn-bordered:focus,
.btn-bordered:active {
  color: var(--light-color);
  opacity: 0.7;
} */

/* .btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  opacity: 0.7;
  color: var(--text-color);
} */

/* .btn-default .icon {
  color: var(--text-color);
} */

.menu-container-no-shadow .dropdown-menu {
  box-shadow: none !important;
}

/* .btn-transparent.primary:hover {
  background-color: var(--primary-color) !important;
  color: var(--light-color);
  opacity: 1;
} */

/* .btn-transparent.default:hover {
  background-color: var(--light-color) !important;
  color: var(--primary-color);
  opacity: 1;
} */

/* .btn-sm {
  min-height: 35px;
}

.btn-xsm {
  min-height: 20px;
  padding: 5px;
} */
