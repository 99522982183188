.blood-assessment-tabs-container {
  /* min-height: 50px; */
  display: flex;
  /* align-items: ce; */
  border-bottom: 1px solid var(--border-color);
}

.blood-assessment-tabs-container .blood-assessment-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 50px;
  cursor: pointer;
  opacity: 1;
  border: 3px solid transparent;
}

.blood-assessment-tabs-container .blood-assessment-tab:hover {
  opacity: 0.8;
}

.blood-assessment-tabs-container .blood-assessment-tab.active {
  border-bottom: 3px solid var(--primary-color);
}

.blood-assessment-tabs-container .blood-assessment-tab.active .tab-name {
  color: var(--primary-color);
}

.blood-assessment-tabs-container .blood-assessment-tab .count {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 1rem;
  color: var(--light-color);
}

.blood-assessment-tabs-container .blood-assessment-tab .count span {
  font-weight: 600;
  font-size: 12px;
}

.donor-number-presentation-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color-10);
}

.donor-number-presentation-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0.5rem 1rem;
  min-height: 40px;
}
