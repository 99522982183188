.main-collection{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    margin: 20px 20px 0 20px;

}

.main-layout{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 20px 0 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
/* ad more space between th and td */


table, th, td {
    /* border: 1px solid black; */
    padding: 1rem;
    text-align: start;    
}

th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
}

.sample-requested{
    margin-bottom: 10px;
    margin-top: 20px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.data-table{
    margin-top: 20px;
}

.patient-info{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    margin-top: 20px;
   
}



.patient-info h3{
    margin-bottom: 10px;
}

.sample-req{
    margin-top: 5px;
}

.sample-req span{
    font-weight: bold;
}

.left-layout{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* horizontal line */
.breaker {
    color: #7a5353;
    height: 300px;
}

.right-layout {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    justify-content: start;
}   

.submit-button {
    margin-top: 20px;
    margin-bottom: 20px;
    
}

.header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center ;
    margin: 20px 20px 0 20px;
}

.shipment-requested{
    margin: 20px;
}

.shipment-requested span{
    font-weight: bold;
}
