/* Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--text-color-10);
  border-radius: var(--border-radius);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color-10);
}
