.sites-landing-header-container {
  margin-bottom: 1rem;
}

.centers.card {
  cursor: pointer;
  opacity: 1;
}

.centers.card:hover {
  opacity: 0.8;
}

.centers .card-body {
  display: flex;
  gap: 1rem;
}

.centers .address-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.centers .center-icon-container {
  background-color: var(--primary-color-5);
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centers .center-icon-container span {
  color: var(--primary-color);
}

.centers .center-info-container {
  flex: 1;
}

.center-map {
  min-height: 30vh;
  background-color: var(--text-color-5);
}

.center-info-item {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.center-info-item-name {
  flex: 1;
}
