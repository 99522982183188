.shipping-slip-header {
  display: flex;
  align-items: center;
}

.shipping-slip-header .shipping-slip-logo {
  width: 150px;
}

.shipping-slip-header .shipping-slip-logo img {
  width: 100%;
  aspect-ratio: 2/2;
  object-fit: contain;
}

.shipping-slip-header .left {
  flex: 1;
}
